.backdrop{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1200;
  background-color: rgba(0, 0, 0, 0.596);
  width: max(100vw, 100vh);
  min-height: 100vh;
}

.noBackdrop{
  display: none;
}
