.modal{
  position: fixed;
  width: 50%;
  min-width: 40%;
  margin: auto;
  /* height: 1vh; */
  max-height : 95vh !important;
  min-height: 430px !important;
  z-index: 1500;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: auto;
}
.modal__small{
  position: fixed;
  width: 30%;
  min-width: 30%;
  margin: auto;
  max-height: 40vh !important;
  min-height: 150px !important;
  z-index: 1300;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  overflow: auto;
}

.modal__medium{
  position: fixed;
  width: 40%;
  min-width: 40%;
  margin: auto;
  max-height: 70vh !important;
  min-height: 300px !important;
  z-index: 1300;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  overflow: auto;
}

.modal__large{
  position: fixed;
  width: 80%;
  min-width: 80%;
  margin: auto;
  max-height : 95vh !important;
  min-height: 430px !important;
  z-index: 1300;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  overflow: auto;
}

@media only screen and (min-width: 768px) {
  .modal__large{
    min-height: 430px !important;
  }
}
@media only screen and (max-width: 767px) {
  .modal__large{
    min-height: 230px !important;
  }
}

.noModal{
  display: none;
}
