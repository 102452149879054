.styled-scroll::-webkit-scrollbar {
    width: 10px;
  }
.styled-scroll::-webkit-scrollbar-track {
    background: rgb(18,18,18);
  }
.styled-scroll::-webkit-scrollbar-thumb {
    background: rgba(128,128,128, 0.5); 
  }
.styled-scroll::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  
  .hide-scroll::-webkit-scrollbar {
    width: 2px;
  }
.hide-scroll::-webkit-scrollbar-track {
    background: grey;
    background: transparent;
  }
.hide-scroll::-webkit-scrollbar-thumb {
    background: rgba(184, 194, 141, 0.5); 
  }
.hide-scroll::-webkit-scrollbar-thumb:hover {
    background: rgb(175, 136, 136);
  }

.container {
    position: absolute;
    /* height: 300px;
    width: 600px; */
    /* top: 60px; */
    /* left: calc(50% - 300px); */
    /* height: 100%; */
    display: flex;
    top : 50%;
    left : 50%;
    transform: translate(-50% , -50%);
    /* padding: 60px; */
    width: 90%;
    /* border: 1px solid red; */
  }
  
  .card {
    display: flex;
    height: 280px;
    width: 170px;
    margin : 10px;
    background-color: #17141d;
    border-radius: 10px;
    /* box-shadow: -1rem 0 3rem #000; */
    box-shadow: -1rem 0 3rem rgb(155, 152, 152, 1);
    /* margin-left: -50px; */
    margin-left: 10px;
    transition: 0.4s ease-out;
    position: relative;
    left: 0px;
  }
  @media (max-width: 768px) {
    .container{
      padding: 30px;
    }
    .card {
      height: 250px;
      /* border: 1px solid red ; */
    }
  }
  
  .card:not(:first-child) {
      margin-left: -50px;
  }
  
  .card:hover {
    transform: translateY(-20px);
    transition: 0.4s ease-out;
  }
  
  .card:hover ~ .card {
    position: relative;
    left: 50px;
    transition: 0.4s ease-out;
  }
  
